import React, { useCallback, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { Button } from '@material-tailwind/react';
import { authGuard } from '../lib/utils.js';
import { useAxios } from '../Components/AxiosProvider.jsx';
import Calendar from '../Components/Calendar.jsx';
import TimezoneSelector from '../Components/timeZoneSelector.jsx';
import Toast from '../Components/Toast.jsx';

const createTimeSlots = () => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = ['00', '30'];

  return hours.flatMap((hour) =>
    minutes.map((minute) => {
      const displayHour = hour % 12 || 12;
      const period = hour < 12 ? 'AM' : 'PM';
      const timeSlot = `${displayHour.toString().padStart(2, '0')}:${minute} ${period}`;
      return {
        label: timeSlot,
        value: `${hour.toString().padStart(2, '0')}:${minute}`,
      };
    }),
  );
};

const Dashboard = () => {
  const auth = authGuard();
  const axios = useAxios();
  const { user } = useUser();
  const [date, setDate] = React.useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = React.useState({ value: 'Asia/Kolkata', label: 'India', offset: '+05:30' });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [calls, setCalls] = useState([]);
  const timeSlots = createTimeSlots();
  const [isLoading, setIsLoading] = useState(false);
  const [showTimeSlots, setShowTimeSlots] = useState(true);
  const [existingCall, setExistingCall] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    //
  }, [auth]);

  const getAllScheduledCalls = async () => {
    try {
      if (!auth) {
        throw new Error('User not authenticated');
      }
      const response = await axios.get('/calls');
      setCalls(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching scheduled calls:', error);
    }
  };

  useEffect(() => {
    getAllScheduledCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
  };

  const handleScheduleCall = async (slotValue, timezoneValue) => {
    setIsLoading(true);

    try {
      if (!auth) {
        setIsLoading(false);
        throw new Error('User not authenticated');
      }

      if (!date) {
        setIsLoading(false);
        throw new Error('Date is not selected');
      }

      // Validate timezone
      if (!timezoneValue || !timezoneValue.value || !timezoneValue.offset) {
        setIsLoading(false);
        throw new Error('Invalid timezone value');
      }

      // Parse the slot time (HH:mm)
      const [hours, minutes] = slotValue.split(':').map(Number);

      if (Number.isNaN(hours) || Number.isNaN(minutes)) {
        setIsLoading(false);
        throw new Error('Invalid time format');
      }

      // Set the date components in the selected timezone
      const scheduleDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        hours,
        minutes,
        0, // seconds
      );

      // Format the complete date with timezone
      const scheduleAt = scheduleDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: timezoneValue.value,
        hour12: false,
      });

      // Convert the formatted date to ISO format with timezone
      const [datePart, timePart] = scheduleAt.split(', ');
      const [month, day, year] = datePart.split('/');
      const scheduleAtWithTz = `${year}-${month}-${day}T${timePart}.000${timezoneValue.offset}`;

      // Ensure the date is in the future
      const currentTime = new Date();
      const scheduledTime = new Date(scheduleAtWithTz);

      if (scheduledTime <= currentTime) {
        setIsLoading(false);
        throw new Error('Cannot schedule calls in the past');
      }

      // Make the API call with combined scheduleAt field
      const response = await axios.post('/calls', {
        scheduledAt: scheduleAtWithTz, // Combined date and timezone in one field
        type: 'FOLLOWUP',
      });

      setIsLoading(false);
      getAllScheduledCalls();
      setToastMessage('Call scheduled successfully!');
      setShowToast(true);

      return {
        success: true,
        data: response.data,
        scheduledDateTime: scheduleAtWithTz,
      };
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const isSlotHighlighted = useCallback(
    (slotValue) => {
      // Format the selected date in the chosen timezone
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: selectedTimezone.value,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      const selectedDateString = formatter.format(date).split('/').reverse().join('-');

      return calls.some((call) => {
        // Format the call date in the same timezone
        const callDate = new Date(call.scheduledAt);
        const callDateString = formatter.format(callDate).split('/').reverse().join('-');
        const callTimeString = new Intl.DateTimeFormat('en-US', {
          timeZone: selectedTimezone.value,
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
          .format(callDate)
          .replace(':', '');
        return callDateString === selectedDateString && callTimeString === slotValue.replace(':', '');
      });
    },
    [calls, date, selectedTimezone?.value],
  );

  const handleConfirmBooking = () => {
    if (selectedSlot) {
      handleScheduleCall(selectedSlot, selectedTimezone);
      setShowToast(false);
    }
  };

  // Add this function to check for existing calls
  const checkExistingCall = useCallback(
    (selectedDate) => {
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: selectedTimezone.value,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      const selectedDateString = formatter.format(selectedDate).split('/').reverse().join('-');

      const callsExists = calls.find((call) => {
        const callDate = new Date(call.scheduledAt);
        const callDateString = formatter.format(callDate).split('/').reverse().join('-');
        return callDateString === selectedDateString;
      });

      return callsExists;
    },
    [calls, selectedTimezone.value],
  );

  // Modify the useEffect for date changes
  useEffect(() => {
    if (date) {
      const existing = checkExistingCall(date);
      setExistingCall(existing);
      setShowTimeSlots(!existing);
    }
  }, [date, checkExistingCall]);

  return (
    <div className="w-full max-w-6xl mx-auto p-4 sm:p-6 bg-white shadow-lg rounded-lg">
      <div className="flex flex-col gap-6 lg:grid lg:grid-cols-3 divide-y md:divide-none lg:divide-x divide-slate-200">
        {/* Left Panel - Responsive Modifications */}
        <div className="p-4 sm:p-8 md:col-span-1">
          <div className="space-y-4 sm:space-y-6">
            <div className="flex items-center sm:items-start flex-row sm:flex-col gap-4">
              <div className="w-10 h-10 sm:w-12 sm:h-12 bg-gradient-to-br from-teal-400 to-blue-400 rounded-full flex-shrink-0" />
              <div>
                <h2 className="text-sm sm:text-base text-gray-600">Hi, {user?.firstName}</h2>
                <p className="font-semibold text-sm sm:text-base">Hana callback</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-5 h-5 text-gray-500">⏱️</div>
              <span className="text-sm sm:text-base">15 min</span>
            </div>
            <p className="text-gray-600 text-sm sm:text-base">Chat with Hana, your AI companion for mental health.</p>
            <ul className="space-y-1 sm:space-y-2 text-gray-600 list-disc pl-5 text-sm sm:text-base">
              <li>Voice-activated interaction</li>
              <li>Personalized assessment</li>
              <li>Confidential and secure</li>
            </ul>
            <p className="text-gray-500 text-xs sm:text-sm">If in crisis, please contact emergency services.</p>
            <p className="text-gray-600 text-sm sm:text-base">Select a time to speak with Hana and take a step towards better mental health.</p>
          </div>
        </div>
        {/* Calendar - Responsive Modifications */}
        <div className="p-4 sm:p-6 md:col-span-1">
          <div className="mb-4 sm:mb-6">
            <div className="flex flex-col sm:flex-row md:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6">
              <div className="flex flex-wrap gap-3 sm:gap-8 items-center mb-2 sm:mb-0">
                {/* Session Indicators - Made more compact */}
                <div className="flex gap-2 items-center">
                  <div className="w-4 h-4 sm:w-5 sm:h-5 rounded-full bg-slate-300" />
                  <span className="text-xs sm:text-sm text-gray-700">Past</span>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="w-4 h-4 sm:w-5 sm:h-5 rounded-full bg-slate-900" />
                  <span className="text-xs sm:text-sm text-gray-700">Future</span>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="w-4 h-4 sm:w-5 sm:h-5 rounded-full bg-red-600" />
                  <span className="text-xs sm:text-sm text-gray-700">Missed</span>
                </div>
              </div>
            </div>
            <h2 className="text-lg sm:text-xl font-semibold">Select a Date & Time</h2>
          </div>
          <div className="flex justify-center">
            <Calendar mode="single" selected={date} onSelect={setDate} className="rounded-lg border-0 w-full max-w-xs" existingCalls={calls} />
          </div>
          <div className="mt-4 sm:mt-14">
            <h4 className="text-xs sm:text-sm font-semibold mb-2">Time Zone</h4>
            <TimezoneSelector selectedTimezone={selectedTimezone} onTimezoneChange={setSelectedTimezone} />
          </div>
        </div>
        {/* Time Slots - Responsive Modifications */}
        <div className="space-y-4">
          {existingCall && date ? (
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center space-x-2">
                  <div className="text-sm sm:text-base font-normal text-slate-500">
                    {date?.toLocaleDateString('en-US', {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </div>
                  <p className="text-sm text-gray-600">
                    {new Date(existingCall.scheduledAt).toLocaleTimeString('en-US', {
                      timeZone: selectedTimezone.value,
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </p>
                </div>
                <button onClick={() => setShowTimeSlots(true)} className="p-2 hover:bg-blue-100 rounded-full transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            <div className="text-sm sm:text-base font-normal text-slate-500 mb-4 sm:mb-6">
              {date?.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })}
            </div>
          )}

          {showTimeSlots && (
            <div className="max-h-[400px] sm:h-[400px] overflow-y-auto scrollbar-hidden">
              <div className="space-y-2 pr-2 sm:pr-4">
                {timeSlots.map((slot) => (
                  <Button
                    key={slot.value}
                    className={`w-full p-1.5 sm:p-2 text-center bg-white border-slate-700 border rounded-md 
            transition-colors font-sans text-slate-900 text-xs sm:text-base
              ${(() => {
                if (selectedSlot === slot.value) {
                  return 'border-slate-900 bg-slate-200';
                }
                if (isSlotHighlighted(slot.value)) {
                  return 'border-blue-600 bg-blue-200';
                }
                return 'border-slate-800 hover:border-slate-900 hover:bg-slate-200 active:bg-slate-100';
              })()}`}
                    onClick={() => handleSlotSelection(slot.value)}
                  >
                    {slot?.label}
                  </Button>
                ))}
              </div>
            </div>
          )}

          {selectedSlot && showTimeSlots && (
            <div className="space-y-2 pr-4 mt-5">
              <Button
                onClick={handleConfirmBooking}
                disabled={isLoading}
                loading={isLoading}
                size="sm"
                className="w-full bg-blue-gray-50 text-xs text-black sm:text-base font-sans mt-1"
              >
                {!isLoading ? `Confirm Booking for ${timeSlots.find((slot) => slot.value === selectedSlot)?.label}` : 'Booking...'}
              </Button>
            </div>
          )}
        </div>
      </div>
      {showToast && <Toast message={toastMessage} />}
    </div>
  );
};

export default Dashboard;
