import React from 'react';
import { FaGlobe as Globe } from 'react-icons/fa';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import PropTypes from 'prop-types';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './select.jsx';

const TimezoneSelector = ({ selectedTimezone, onTimezoneChange }) => {
  const [currentTime, setCurrentTime] = React.useState(new Date());

  // Comprehensive list of timezones grouped by region
  const timezones = [
    {
      region: 'North America',
      zones: [
        { value: 'America/New_York', label: 'Eastern Time', offset: '-05:00' },
        { value: 'America/Chicago', label: 'Central Time', offset: '-06:00' },
        { value: 'America/Denver', label: 'Mountain Time', offset: '-07:00' },
        { value: 'America/Los_Angeles', label: 'Pacific Time', offset: '-08:00' },
        { value: 'America/Anchorage', label: 'Alaska Time', offset: '-09:00' },
        { value: 'Pacific/Honolulu', label: 'Hawaii Time', offset: '-10:00' },
        { value: 'America/Toronto', label: 'Toronto', offset: '-05:00' },
        { value: 'America/Vancouver', label: 'Vancouver', offset: '-08:00' },
        { value: 'America/Mexico_City', label: 'Mexico City', offset: '-06:00' },
      ],
    },
    {
      region: 'South America',
      zones: [
        { value: 'America/Sao_Paulo', label: 'São Paulo', offset: '-03:00' },
        { value: 'America/Buenos_Aires', label: 'Buenos Aires', offset: '-03:00' },
        { value: 'America/Santiago', label: 'Santiago', offset: '-04:00' },
        { value: 'America/Lima', label: 'Lima', offset: '-05:00' },
        { value: 'America/Bogota', label: 'Bogota', offset: '-05:00' },
      ],
    },
    {
      region: 'Europe',
      zones: [
        { value: 'Europe/London', label: 'London (BST/GMT)', offset: '+00:00' },
        { value: 'Europe/Paris', label: 'Paris (CET)', offset: '+01:00' },
        { value: 'Europe/Berlin', label: 'Berlin', offset: '+01:00' },
        { value: 'Europe/Madrid', label: 'Madrid', offset: '+01:00' },
        { value: 'Europe/Rome', label: 'Rome', offset: '+01:00' },
        { value: 'Europe/Amsterdam', label: 'Amsterdam', offset: '+01:00' },
        { value: 'Europe/Brussels', label: 'Brussels', offset: '+01:00' },
        { value: 'Europe/Vienna', label: 'Vienna', offset: '+01:00' },
        { value: 'Europe/Moscow', label: 'Moscow', offset: '+03:00' },
        { value: 'Europe/Istanbul', label: 'Istanbul', offset: '+03:00' },
      ],
    },
    {
      region: 'Asia',
      zones: [
        { value: 'Asia/Dubai', label: 'Dubai', offset: '+04:00' },
        { value: 'Asia/Shanghai', label: 'China', offset: '+08:00' },
        { value: 'Asia/Tokyo', label: 'Japan', offset: '+09:00' },
        { value: 'Asia/Seoul', label: 'Korea', offset: '+09:00' },
        { value: 'Asia/Singapore', label: 'Singapore', offset: '+08:00' },
        { value: 'Asia/Hong_Kong', label: 'Hong Kong', offset: '+08:00' },
        { value: 'Asia/Kolkata', label: 'India', offset: '+05:30' },
        { value: 'Asia/Bangkok', label: 'Bangkok', offset: '+07:00' },
        { value: 'Asia/Jakarta', label: 'Jakarta', offset: '+07:00' },
        { value: 'Asia/Manila', label: 'Manila', offset: '+08:00' },
        { value: 'Asia/Taipei', label: 'Taipei', offset: '+08:00' },
      ],
    },
    {
      region: 'Oceania',
      zones: [
        { value: 'Pacific/Auckland', label: 'New Zealand', offset: '+12:00' },
        { value: 'Australia/Sydney', label: 'Sydney', offset: '+10:00' },
        { value: 'Australia/Melbourne', label: 'Melbourne', offset: '+10:00' },
        { value: 'Australia/Perth', label: 'Perth', offset: '+08:00' },
        { value: 'Australia/Brisbane', label: 'Brisbane', offset: '+10:00' },
      ],
    },
    {
      region: 'Africa',
      zones: [
        { value: 'Africa/Cairo', label: 'Cairo', offset: '+02:00' },
        { value: 'Africa/Johannesburg', label: 'Johannesburg', offset: '+02:00' },
        { value: 'Africa/Lagos', label: 'Lagos', offset: '+01:00' },
        { value: 'Africa/Nairobi', label: 'Nairobi', offset: '+03:00' },
        { value: 'Africa/Casablanca', label: 'Casablanca', offset: '+00:00' },
      ],
    },
  ];

  // Function to find the full timezone object from value
  const findTimezoneObject = (searchValue) => {
    return timezones.flatMap((region) => region.zones).find((zone) => zone.value === searchValue);
  };

  // Update time every minute
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  // Handle value change to pass full timezone object
  const handleTimezoneChange = (value) => {
    const timezoneObject = findTimezoneObject(value);
    if (timezoneObject) {
      onTimezoneChange(timezoneObject);
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <Select value={selectedTimezone?.value || ''} onValueChange={handleTimezoneChange}>
        <SelectTrigger className="w-full">
          <div className="flex items-center gap-2">
            <Globe className="w-4 h-4 text-gray-500" />
            <SelectValue placeholder="Select timezone" />
          </div>
        </SelectTrigger>
        <SelectContent>
          {timezones.map((region) => (
            <React.Fragment key={region.region}>
              <div className="px-2 py-1.5 text-sm font-semibold text-gray-500">{region.region}</div>
              {region.zones.map((timezone) => (
                <SelectItem key={timezone.value} value={timezone.value}>
                  <div className="flex justify-between items-center w-full">
                    <span>{timezone?.label}</span>
                    <span className="text-gray-500 text-sm">({format(toZonedTime(currentTime, timezone.value), 'h:mma')})</span>
                  </div>
                </SelectItem>
              ))}
            </React.Fragment>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

TimezoneSelector.propTypes = {
  selectedTimezone: PropTypes.object.isRequired,
  onTimezoneChange: PropTypes.func.isRequired,
};

export default TimezoneSelector;
