import { DayPicker } from 'react-day-picker';
import PropTypes from 'prop-types';
import { isBefore } from 'date-fns';
import { cn } from '../lib/utils.js';
import 'react-day-picker/dist/style.css';

const Calendar = ({ className, classNames, showOutsideDays = true, existingCalls = [], ...props }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const modifiers = {
    past_call: (date) => {
      const callOnDate = existingCalls.find((call) => {
        const callDate = new Date(call.scheduledAt);
        return callDate.toDateString() === date.toDateString() && callDate <= new Date() && ['COMPLETED'].includes(call.status);
      });
      return !!callOnDate;
    },
    future_call: (date) => {
      const callOnDate = existingCalls.find((call) => {
        const callDate = new Date(call.scheduledAt);
        return callDate.toDateString() === date.toDateString() && callDate >= new Date() && call.status === 'PENDING';
      });
      return !!callOnDate;
    },
    missed_call: (date) => {
      const callOnDate = existingCalls.find((call) => {
        const callDate = new Date(call.scheduledAt);
        return callDate.toDateString() === date.toDateString() && call.status === 'MISSED';
      });
      return !!callOnDate;
    },
    disabled: (date) => isBefore(date, today),
  };

  const modifiersClassNames = {
    past_call: 'bg-slate-500 text-white',
    future_call: 'bg-black text-white',
    missed_call: 'bg-red-500 text-white',
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      modifiers={modifiers}
      modifiersClassNames={modifiersClassNames}
      classNames={{
        day: 'h-10 w-10 text-center p-0 rounded-full hover:bg-gray-100',
        selected:
          'bg-slate-100 text-gray-900 hover:bg-slate-200 hover:text-slate-900 focus:bg-slate-200 focus:text-slate-900 border border-black rounded-full w-10 h-10 flex items-center justify-center',
        today: 'bg-white border border-slate-200 text-slate-900',
        disabled: 'opacity-50 cursor-not-allowed',
      }}
      {...props}
    />
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.object,
  showOutsideDays: PropTypes.bool,
  existingCalls: PropTypes.arrayOf(
    PropTypes.shape({
      scheduledAt: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ),
};

export default Calendar;
