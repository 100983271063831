export const WeeklyMentalHealthReportSkeleton = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-8 animate-pulse">
      <div className="flex justify-between items-center mb-4">
        <div className="bg-gray-300 w-32 h-8 rounded" />
        <div className="bg-gray-300 w-24 h-6 rounded" />
      </div>
      <div className="grid grid-cols-3 gap-8">
        <div className="space-y-4">
          <div className="bg-gray-300 w-full h-8 rounded" />
          <div className="bg-gray-300 w-full h-8 rounded" />
          <div className="bg-gray-300 w-full h-8 rounded" />
        </div>
        <div className="space-y-4">
          <div className="bg-gray-300 w-full h-8 rounded" />
          <div className="bg-gray-300 w-full h-8 rounded" />
          <div className="bg-gray-300 w-full h-8 rounded" />
        </div>
        <div className="space-y-4">
          <div className="bg-gray-300 w-full h-8 rounded" />
          <div className="bg-gray-300 w-full h-8 rounded" />
          <div className="bg-gray-300 w-full h-8 rounded" />
        </div>
      </div>
      <div className="mt-6 space-y-3">
        <div className="bg-gray-300 w-full h-6 rounded" />
        <div className="bg-gray-300 w-full h-6 rounded" />
        <div className="bg-gray-300 w-full h-6 rounded" />
      </div>
    </div>
  );
};

export const FollowupSkeleton = () => (
  <div>
    <div className="space-y-4">
      <div className="flex items-center bg-white justify-between px-4 py-4 gap-6 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center bg-white justify-between px-4 mt-6 gap-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center bg-white justify-between px-4 mt-6 gap-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center bg-white justify-between px-4 mt-6 gap-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center bg-white justify-between px-4 mt-6 gap-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center bg-white justify-between px-4 mt-6 gap-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center bg-white justify-between px-4 mt-6 gap-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
      <div className="flex items-center bg-white justify-between px-4 mt-6 gap-6 py-4 border-b">
        <div className="flex items-center space-x-3">
          <div className="w-6 h-6 bg-gray-200 rounded"></div>
          <div className="w-24 h-5 bg-gray-200 rounded"></div>
        </div>
        <div className="w-6 h-6 bg-gray-200 rounded"></div>
      </div>
    </div>
  </div>
);
