import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer } from 'recharts';
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../Components/Loading.jsx';
import EmailVerification from '../Components/verifyEmail.jsx';

const Section = ({ title, icon, children }) => {
  Section.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
  };
  return (
    <div className="bg-white shadow-md rounded-lg my-6 overflow-hidden">
      <div className="bg-blue-100 px-6 py-4 flex items-center">
        <span className="text-2xl mr-3">{icon}</span>
        <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
      </div>
      <div className="p-6">{children}</div>
    </div>
  );
};

const MentalHealthReport = () => {
  // const {userData, radarChartData, symptoms, positiveFactors, attentionAreas, context } = report;

  const { user } = useUser();
  const { reportId } = useParams();
  const [report, setReport] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${import.meta.env.VITE_APP_URL ?? ''}/report/intake/${reportId}`, {
        headers: { Accept: 'application/json' },
      });
      setReport(await response.json());
    };

    fetchData();
  }, [reportId]);

  const isEmailVerified = user.emailAddresses.some((emailObj) => emailObj.verification.status === 'verified');
  if (!isEmailVerified) {
    return <EmailVerification />;
  }

  if (!report) return <Loading />;
  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-50">
      <div className="bg-blue-100 rounded-t-lg p-8 text-white">
        <h1 className="text-4xl text-black font-bold mb-6">Your Mental Health Overview</h1>

        <div className="grid grid-cols-2 gap-8 text-lg">
          <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
            <span className="font-medium mr-2 text-slate-500">Name:</span>
            <span className="font-semibold text-black">{report.userData.name}</span>
          </div>
          <div className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
            <span className="font-medium mr-2 text-slate-500">Email:</span>
            <span className="font-semibold text-black">{report.userData.email}</span>
          </div>
        </div>
      </div>

      <Section title="Current Symptoms Summary" icon="📋">
        <p className="mb-4">{report.symptoms.intro}</p>
        <ul className="list-disc pl-5 space-y-2">
          {report.symptoms.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </Section>

      <Section title="Current Life Functioning" icon="📊">
        <div className="h-80 mb-4">
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={report.radarChartData}>
              <PolarGrid strokeDasharray="3 3" />
              <PolarAngleAxis dataKey="category" tick={{ fill: '#4B5563', fontSize: 12 }} />
              <PolarRadiusAxis angle={30} domain={[0, 100]} tick={{ fill: '#4B5563' }} />
              <Radar name={report.userData.name} dataKey="value" stroke="#3B82F6" fill="#3B82F6" fillOpacity={0.4} />
            </RadarChart>
          </ResponsiveContainer>
        </div>
        <p className="text-gray-600">
          This chart illustrates different areas of your current functioning. Higher values indicate better functioning in that area.
        </p>
      </Section>

      <Section title="Positive Factors" icon="✨">
        <ul className="list-disc pl-5 space-y-2">
          {report.positiveFactors.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </Section>

      <Section title="Areas for Attention" icon="🎯">
        <ol className="list-decimal pl-5 space-y-2">
          {report.attentionAreas.map((item, index) => (
            <li key={index}>
              <strong>{item.title}:</strong> {item.description}
            </li>
          ))}
        </ol>
      </Section>

      <Section title="Context and Background" icon="📝">
        <p className="mb-4">{report.context.intro}</p>
        <ul className="list-disc pl-5 space-y-2">
          {report.context.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </Section>

      <div className="mt-6 p-4 bg-blue-50 rounded-lg border border-blue-100 text-sm text-gray-600">
        <p>
          <strong>Note:</strong> This overview is based on our conversation and is meant to provide a snapshot of your current mental health situation. It is
          not a medical diagnosis. If your symptoms persist or worsen, consider consulting with a healthcare provider for a comprehensive evaluation.
        </p>
      </div>
    </div>
  );
};

export default MentalHealthReport;
