import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Toast = ({ message, duration = 3000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <div
      className={`fixed top-4 right-4 max-w-sm px-4 py-3 bg-green-600 text-white rounded-lg shadow-lg transition-transform duration-500 ease-in-out transform ${
        visible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
      }`}
      style={{
        transitionProperty: 'transform, opacity',
        transitionTimingFunction: 'ease-in-out',
        transitionDuration: '500ms',
        pointerEvents: visible ? 'auto' : 'none',
      }}
    >
      {message}
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  duration: PropTypes.number,
};

export default Toast;
