/* eslint-disable react-hooks/rules-of-hooks */
import { twMerge } from 'tailwind-merge';
import { useAuth, useUser } from '@clerk/clerk-react';

export const cn = (...inputs) => {
  return twMerge(inputs);
};

export const authGuard = () => {
  const { getToken, userId, isLoaded, isSignedIn } = useAuth();
  const { user } = useUser();
  if (!isLoaded) {
    return null;
  }
  return {
    user: {
      id: userId,
      getToken,
      isSignedIn,
      userInfo: user,
    },
  };
};
