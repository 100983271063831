// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState } from 'react';
import { RedirectToSignIn, useAuth, UserButton, useUser } from '@clerk/clerk-react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FaHome, FaFileMedical, FaFileSignature } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Loading from '../Components/Loading.jsx';
import { AxiosProvider, useAxios } from '../Components/AxiosProvider.jsx';

const Sidebar = ({ reportId, location, navigate, isOpen, toggleSidebar }) => {
  Sidebar.propTypes = {
    reportId: PropTypes.string,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
  };

  if (!reportId) {
    navigate('/auth/call');
  }

  const sidebarItems = [
    {
      icon: <FaFileMedical />,
      label: 'Intake Report',
      path: reportId ? `/auth/report/${reportId}` : null,
      onClick: () => {
        if (reportId) {
          navigate(`/auth/report/${reportId}`);
        } else {
          alert('Report ID not found!');
        }
      },
    },
    {
      icon: <FaHome />,
      label: 'Schedule Followup',
      path: '/auth/dashboard',
      onClick: () => navigate('/auth/dashboard'),
    },
    {
      icon: <FaFileSignature />,
      label: 'Followup Report',
      path: '/auth/followup',
      onClick: () => navigate('/auth/followup'),
    },
  ];

  return (
    <>
      {/* Sidebar - mobile overlay and static on larger screens */}
      <div
        className={`fixed md:static top-0 left-0 h-full w-72 bg-white shadow-lg border-r border-gray-100 transform transition-transform duration-300 ease-in-out z-40 
          ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}
      >
        <div className="p-6">
          <div className="space-y-2">
            {sidebarItems.map((item, index) => (
              <button
                key={index}
                onClick={item.onClick}
                disabled={!item.path}
                className={`
                  w-full text-left px-4 py-3 rounded-xl transition-all duration-300 ease-in-out
                  flex items-center group
                  ${
                    location.pathname === item.path
                      ? 'bg-gradient-to-r from-teal-500/20 to-purple-500/20 text-teal-700 shadow-md'
                      : 'text-gray-600 hover:bg-gradient-to-r hover:from-teal-500/10 hover:to-purple-500/10 hover:shadow-sm'
                  }
                  ${!item.path ? 'opacity-50 cursor-not-allowed' : 'hover:translate-x-1'}
                `}
              >
                <span
                  className={`
                    mr-4 text-xl transition-all duration-300
                    ${location.pathname === item.path ? 'text-teal-600 scale-110' : 'text-gray-400 group-hover:text-teal-500 group-hover:scale-110'}
                  `}
                >
                  {item.icon}
                </span>
                <span className="text-sm font-medium tracking-wide">{item.label}</span>
                {location.pathname === item.path && (
                  <span className="ml-auto text-teal-600">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Overlay - only on mobile */}
      {isOpen && <div className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden" onClick={toggleSidebar} />}
    </>
  );
};

const Topbar = ({ user, toggleSidebar }) => {
  Topbar.propTypes = {
    user: PropTypes.object,
    toggleSidebar: PropTypes.func,
  };
  return (
    <div className="w-full bg-gradient-to-r from-[#A4D4EA] via-[#BED1F9] via-[#DEE2ED] via-[#FAF0E6] to-[#FDF6E3] flex p-4 shadow-sm h-16 flex items-center justify-between px-4">
      <div className="flex items-center space-x-5">
        {/* Sidebar toggle button */}
        <button className="p-1  bg-teal-500 text-white rounded-md shadow-md md:hidden" onClick={toggleSidebar}>
          ☰
        </button>

        {/* Logo */}
        <img src="/logo.png" alt="Hana Logo" className="h-10" aria-label="Hana Logo" />
      </div>

      <div className="flex items-center space-x-4">
        <div className="hidden sm:flex items-center space-x-3">
          <div className="flex flex-col items-end">
            <p className="font-medium text-gray-700 text-sm sm:text-base">{user?.fullName}</p>
          </div>
          <UserButton />
        </div>
        <div className="sm:hidden flex items-center">
          <UserButton />
        </div>
      </div>
    </div>
  );
};

// Container Component
const Container = () => {
  const { getToken } = useAuth();
  const [token, setToken] = useState(null);

  useEffect(() => {
    getToken().then((t) => setToken(t));
  }, [getToken]);

  if (!token) return <Loading />;
  return (
    <AxiosProvider jwtToken={token}>
      <AuthedContainer />
    </AxiosProvider>
  );
};

// Authed Container Component
const AuthedContainer = () => {
  const { user } = useUser();
  const { isLoaded, userId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxios();
  const [reportId, setReportId] = useState(null);

  const fetchReportId = useCallback(async () => {
    if (!axios) return;
    try {
      const response = await axios.get('calls/reports', {
        params: {
          limit: 1,
          offset: 0,
          reportType: 'INTAKE',
        },
      });
      setReportId(response?.data?.data[0]?.id);
    } catch (error) {
      console.error('Error fetching report ID:', error);
    }
  }, [axios]);

  useEffect(() => {
    if (axios && !reportId) {
      fetchReportId();
    }
  }, [axios, reportId, fetchReportId]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  if (!isLoaded) return <Loading />;
  if (!userId) return <RedirectToSignIn />;

  return (
    <div className="flex flex-col h-screen">
      <Topbar user={user} toggleSidebar={toggleSidebar} />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar reportId={reportId} location={location} navigate={navigate} isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <main className="flex-1 overflow-auto p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Container;
