import { useUser, useClerk } from '@clerk/clerk-react';
import { Button, Typography, Dialog, DialogHeader, DialogBody, DialogFooter } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const EmailVerification = () => {
  const { user } = useUser();
  const { reportId } = useParams();
  const { signOut } = useClerk();
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
  const [error, setError] = useState(null);

  const handleSendVerificationEmail = async () => {
    if (!user) return;
    console.log('Sending verification email...');
    try {
      // Get the primary email address
      const primaryEmail = user?.emailAddresses.find((email) => email?.id === user?.primaryEmailAddressId);
      if (primaryEmail) {
        // Prepare email verification
        await primaryEmail.prepareVerification({
          strategy: 'email_link',
          redirectUrl: `${window.location.origin}/auth/report/${reportId}`,
        });
        setIsVerificationEmailSent(true);
        setError(null);
      }
    } catch (err) {
      console.error('Failed to send verification email:', err);
      setError('Failed to send verification email. Please try again.');
    }
  };

  useEffect(() => {
    if (user?.emailAddresses.length === 0) {
      signOut();
    }
  }, [user, signOut]);

  const renderVerificationContent = () => {
    if (isVerificationEmailSent) {
      return (
        <>
          <DialogHeader>Verification Email Sent</DialogHeader>
          <DialogBody>
            <Typography>
              A verification email has been sent to {user.emailAddresses[0].emailAddress}. Please check your inbox and click the verification link.
            </Typography>
          </DialogBody>
          <DialogFooter>
            <Button size="sm" onClick={() => setIsVerificationEmailSent(false)}>
              Close
            </Button>
          </DialogFooter>
        </>
      );
    }

    return (
      <>
        <DialogHeader>Email Verification Required</DialogHeader>
        <DialogBody>
          <Typography className="mb-4">Your email address is not verified. Please verify your email to continue.</Typography>
          {error && (
            <Typography color="red" className="mb-4">
              {error}
            </Typography>
          )}
        </DialogBody>
        <DialogFooter>
          <Button size="sm" className="bg-black" onClick={handleSendVerificationEmail} color="black">
            Send Verification Email
          </Button>
          <Button size="sm" onClick={() => signOut()} variant="outlined" className="ml-2">
            Log Out
          </Button>
        </DialogFooter>
      </>
    );
  };

  return (
    <Dialog open={true} handler={() => {}}>
      {renderVerificationContent()}
    </Dialog>
  );
};

export default EmailVerification;
